import React from "react";
import "./ServicesTimeline.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Import images
import uiuxImg from "./assets/uiuxImg.png";
import wevdevImg from "./assets/webdevImg.png";
import projectImg from "./assets/projectImg.jpg";
import androidImg from "./assets/androidImg.png";
import Timeline from "./Timeline";
import { Link } from "react-router-dom";

const technologies = [
  {
    id: 1,
    image: uiuxImg,
    alt: "UI/UX Designing",
    title: "UI/UX Designing",
    description:
      "We craft intuitive and visually stunning interfaces that elevate user experiences. From wireframes to final prototypes, our designs ensure seamless interaction and brand consistency.",
    containerClass: "left-container",
    imgtype: "left-img",
    link: "/uiux-design",
  },
  {
    id: 2,
    image: wevdevImg,
    alt: "Web Development",
    title: "Web Development",
    description:
      "Our full-stack web development services turn ideas into scalable and high-performing web applications, ensuring responsive designs and dynamic functionality using the latest web technologies.",
    containerClass: "right-container",
    imgtype: "right-img",
    link: "/web-development",
  },
  {
    id: 3,
    image: projectImg,
    alt: "Project Management",
    title: "Project Management",
    description:
      "We ensure project success with structured planning, agile methodologies, and continuous client collaboration. From initiation to delivery, we manage every phase to keep your project on track.",
    containerClass: "left-container",
    imgtype: "left-img",
    link: "/product-development",
  },
  {
    id: 4,
    image: androidImg,
    alt: "App Development",
    title: "App Development",
    description:
      "We build robust, user-centric mobile applications tailored to your business needs, combining performance and scalability to deliver exceptional app experiences across platforms.",
    containerClass: "right-container",
    imgtype: "right-img",
    link: "/mobile-app-development",
  },
];

const ServicesTimeline = () => {
  return (
    <div className="main-timeline">
      <div className="sizeBox">
        <h1>Find the Perfect Service</h1>
        <p>
          The entire cycle of digital transformation effortlessly handled for
          you.
        </p>

        <div className="timeContent">
          <div className="timelinediv">
            <Timeline />
          </div>

          <div className="serviceTime">
            {technologies.map((tech, index) => (
              <ServiceItem key={tech.id} tech={tech} delay={index * 0.2} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceItem = ({ tech, delay }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // for only once time it run
    threshold: 0.1,
  });

  const variants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      className={`container ${tech.containerClass}`}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={variants}
      transition={{
        duration: 0.8,
        ease: "easeOut",
        delay,
      }}
    >
      <div className={`imgBox ${tech.imgtype}`}>
        <img src={tech.image} alt={tech.alt} draggable="false" />
      </div>

      <div className="rounded-box">
        <div className="innerRound"></div>
      </div>
      <div className="rounded-box-smallbox"></div>

      <div className="timelineBoxContent">
        <h2>{tech.title}</h2>
        <div className="techContBox">
          <p>{tech.description}</p>
          <Link to={tech.link}>Explore Now</Link>
        </div>
      </div>
    </motion.div>
  );
};

export default ServicesTimeline;
