import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./TeamSlider.css";

import slide_image_1 from "./Assets/Jalandhar_Raur.jpg";
import slide_image_2 from "./Assets/Anmol_Rana.jpg";
import slide_image_3 from "./Assets/Shilajit_Acharjee.png";
import slide_image_4 from "./Assets/Vikash_Kumar.jpg";
import slide_image_5 from "./Assets/Khusbhu_Kirar.jpg";
import slide_image_6 from "./Assets/Abhinav_Patra.jpg";

const TeamSlider = () => {
  const teamsData = [
    {
      name: "Jalandhar Raur",
      role: "Founder & Chief Executive Officer",
      img: slide_image_1,
      quotes:
        "Leadership is not just about steering the ship; it's about inspiring every person on board to believe in the journey and contribute to something larger than themselves. True innovation happens when you're unafraid to challenge the norms and push boundaries.",
    },
    {
      name: "Anmol Rana",
      role: "Project Manager",
      img: slide_image_2,
      quotes:
        "A successful project is built on more than just timelines and deliverables; it's about bringing together diverse minds and skillsets, fostering collaboration, and driving each team member towards a shared vision. The key to my success is making every challenge feel like an opportunity for growth.",
    },
    {
      name: "Shilajit Acharjee",
      role: "Chief Technology Officer",
      img: slide_image_3,
      quotes:
        "In the fast-evolving world of technology, innovation is not a luxury—it's a necessity. My mission is to ensure we are always ahead of the curve, transforming creative ideas into cutting-edge solutions that solve real-world problems and elevate our business to new heights.",
    },
    {
      name: "Vikash Yadav",
      role: "Chief Financial Officer",
      img: slide_image_4,
      quotes:
        "Finance is the backbone of any organization, but it's more than just numbers. It’s about strategically guiding resources to create sustainable growth while maintaining financial health. My focus is not just on the present but building a foundation for long-term success and stability.",
    },
    {
      name: "Khusbhu Kirar",
      role: "IT Executive",
      img: slide_image_5,
      quotes:
        "Technology is not just about solving problems; it's about anticipating the needs of tomorrow. My goal is to leverage IT innovations to enhance efficiency and drive business transformation in a rapidly evolving digital landscape.",
    },
    {
      name: "Abhinav Patra",
      role: "HR Manager",
      img: slide_image_6,
      quotes:
        "Human resources is not just about managing people, but about empowering them to reach their full potential. I focus on creating an environment where employees feel valued, motivated, and aligned with the company's goals for mutual success.",
    },
  ];

  return (
    <div className="teams-slider-container">
      <div className="teamBox">
        <h1 className="heading">Meet Our Talented Team</h1>
        <p>We are already a Family of 20+ Talented Individuals & Growing.</p>

        <div className="teamSlider">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={1}
            spaceBetween={40}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            }}
            pagination={{ clickable: true }}
            navigation={{ clickable: true }}
            autoplay={{ delay: 7000 }}
            modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
            className="swiper_container"
          >
            {Array.from({ length: Math.ceil(teamsData.length / 2) }).map(
              (_, i) => (
                <SwiperSlide key={i}>
                  <div className="grid-container">
                    {teamsData.slice(i * 2, i * 2 + 2).map((t, idx) => (
                      <div className="teamIndividual" key={idx}>
                        <img src={t?.img} alt="" draggable="false" />
                        <div className="data">
                          <span>{t?.name}</span>
                          <span>{t?.role}</span>
                          <p>{t?.quotes}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default TeamSlider;
