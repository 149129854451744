import React from "react";
import "./Service.css";
import { MdArrowOutward } from "react-icons/md";
import { ServiceData } from "./assets/ServiceData";
import arrow from "./assets/arrow.svg";
import { Link } from "react-router-dom";
import Slider from "../../Components/Slider/Slider";

import slide1 from "./assets/slide1.jpg";
import slide2 from "./assets/slide2.jpg";
import slide3 from "./assets/slide3.jpg";

const services = [
  {
    title: "UI/UX Designing",
    description:
      "Craft intuitive and visually appealing interfaces that enhance user experience and engagement. Our design process focuses on usability, aesthetics, and seamless interactions.",
    link: "/uiux-design",
  },
  {
    title: "Web Development",
    description:
      "Build fast, responsive, and secure websites that deliver exceptional performance across all devices. We create websites tailored to your business goals using modern technologies.",
    link: "/web-development",
  },
  {
    title: "Project Management",
    description:
      "Ensure your projects run smoothly with our expert project management services. From planning to execution, we focus on delivering quality outcomes within budget and timelines.",
    link: "/product-development",
  },
  {
    title: "App Development",
    description:
      "Develop robust mobile applications that offer seamless performance and user experience on both Android and iOS platforms. We create apps that drive business growth.",
    link: "/mobile-app-development",
  },
  {
    title: "Database Development",
    description:
      "Design and develop scalable databases that ensure efficient data storage, retrieval, and management. Our database solutions are built for high performance and security.",
    link: "/databases",
  },
  {
    title: "PWA Development",
    description:
      "Leverage the power of Progressive Web Apps (PWAs) to deliver a fast, reliable, and engaging user experience on any device. PWAs combine the best of web and mobile apps.",
    link: "/pwa-development",
  },
  {
    title: "Software Testing",
    description:
      "Ensure the quality and reliability of your software with our comprehensive testing services. From manual to automated testing, we help deliver bug-free and robust applications.",
    link: "/software-testing",
  },
  {
    title: "Cloud Services",
    description:
      "Optimize your business with cloud solutions that offer scalability, flexibility, and cost-efficiency. We help migrate, manage, and secure your data in the cloud.",
    link: "/cloud-services",
  },
  {
    title: "Web API Development",
    description:
      "Develop powerful and secure APIs to enable seamless communication between different software systems. Our APIs are designed for performance and scalability.",
    link: "/web-api",
  },
  {
    title: "Reporting Services",
    description:
      "Transform raw data into actionable insights with our customized reporting solutions. We create detailed and visually appealing reports to help you make informed decisions.",
    link: "/reporting",
  },
];


const slides = [
  {
    heading: "Exploring Key Software Services",
    text: "Exploring key software services reveals how they can enhance business operations and drive growth. Essential services include cloud computing solutions that offer scalable infrastructure and remote access, cybersecurity measures that safeguard digital assets, and data analytics tools that provide valuable insights for strategic decisions. By leveraging these services, organizations can improve efficiency, strengthen security, and harness data to make informed choices. ",
    image: slide1,
  },
  {
    heading: "How Software Services are Revolutionizing Business Operations",
    text: "Software services are revolutionizing business operations by introducing innovative solutions that enhance efficiency and drive growth. Cloud computing services offer scalable infrastructure and remote access, enabling businesses to operate flexibly and cost-effectively. Cybersecurity services are crucial for protecting digital assets from evolving threats, ensuring data integrity and compliance. Additionally, data analytics services provide actionable insights, helping organizations make informed decisions and optimize strategies. ",
    image: slide2,
  },
  {
    heading: "Essential Software Services for Modern Organizations",
    text: "Essential software services are critical for modern organizations looking to optimize their operations and maintain a competitive edge. Key services include cloud computing, which offers scalable and flexible infrastructure for efficient data management and remote access; cybersecurity solutions that protect against data breaches and ensure regulatory compliance; and data analytics tools that deliver actionable insights for strategic decision-making. Implementing these services helps organizations enhance operational efficiency, safeguard their digital assets.",
    image: slide3,
  },
];

const Services = () => {
  return (
    <div className="service-main">
      {/* <div className="our-service">
                <div className="overlay">
                    <h1>Our Services</h1>
                    <p>"Empower Your Business with Cutting-Edge Tech Solutions. Transformative Innovations Await!"</p>
                </div>
            </div> */}
      <div>
        <Slider slides={slides} />
      </div>

      <div className="service-middle">
        <h2>Find a Service that works for you</h2>
        <p>
          The entire cycle of digital transformation effortlessly handled for
          you.
        </p>
        <div className="services-list">
          {services.map((service, index) => (
            <div
              className={`service-item ${index === 1 ? "active" : ""}`}
              key={index}
            >
              <h2>{service.title}</h2>
              <div className="service-des">
                <p>{service.description}</p>
              </div>
              <Link className="icon" to={service.link}>
                <MdArrowOutward />
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className="team-developers-container">
          <div className="header">
            <div className="service-cut1"></div>
            <div className="service-corner1"></div>
            <div className="service-corner2"></div>
            <div className="service-cut2"></div>
            <h1>Hire permanent and remote team developers</h1>
            <p>
              From full-time remote engineering teams to hourly contractors,
              work with remote devs as needed
            </p>
          </div>
          <div className="services-data">
            {ServiceData.map((service, index) => (
              <div className="service-card" key={index}>
                <div className="icon-img">
                  <img src={service.iconimg} alt="" />
                </div>
                <h2>{service.title}</h2>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="service-last">
        <div className="service-lhead">
          <h2>Let’s Create an amazing project together .</h2>
        </div>
        <div className="service-unique">
          <div className="service-join">
            <p style={{ fontWeight: "600" }}>Join us</p>
          </div>
          <div className="service-cross"></div>

          <div className="service-circle">
            <div className="service-arrow">
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
