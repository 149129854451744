import React from "react";

import "./Sheet.css";
import SideBar from "../../PowerSheetEmployee/SideBar/SideBar";

const Sheet = ({ element }) => {
  return (
    <>
      <SideBar />
      <div className="powerSheetCont">{element}</div>
    </>
  );
};

export default Sheet;
