const designerRole = [
  {
    id: 1,
    role: "UI/UX Designer",
    skills: [
      "Figma",
      "Adobe XD",
      "User Research",
      "Wireframing",
      "Prototyping",
    ],
  },
  {
    id: 2,
    role: "Graphic Designer",
    skills: [
      "Adobe Photoshop",
      "Adobe Illustrator",
      "Branding",
      "Logo Design",
      "Typography",
    ],
  },
  {
    id: 3,
    role: "Product Designer",
    skills: [
      "Figma",
      "Sketch",
      "Prototyping",
      "User Testing",
      "Wireframing",
    ],
  },
  {
    id: 4,
    role: "Motion Graphics Designer",
    skills: [
      "Adobe After Effects",
      "Cinema 4D",
      "Animation",
      "Video Editing",
      "Storyboarding",
    ],
  },
];

export default designerRole;
