import React, { useState } from "react";
import "./Contact.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { backendURL } from "../../Helper/BaseUrl";

function Contact() {
  const [values, setValues] = useState({
    username: "",
    mobile: "",
    email: "",
    company: "",
    projectDetails: "",
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const toastAlert = (msg) => {
    toast.error(`${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const toastSuccess = (msg) => {
    toast.success(`${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      values.username &&
      values.mobile &&
      values.email &&
      values.company &&
      values.projectDetails
    ) {
      axios
        .post(`${backendURL}/contact/create-contact`, values)
        .then((res) => {
          // console.log(res.data);
          toastSuccess("Thank You");
        })
        .catch((err) => {
          // console.log(err);
          toastAlert("Server error!!");
        });
    }

    setValues({
      username: "",
      mobile: "",
      email: "",
      company: "",
      projectDetails: "",
    });
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />

      <div className="conatctContainer">
        <div className="first_sec">
          <div className="sizeBox">
            <div className="thought">
              <h3>Contact Us</h3>
              <span>
                If you have any questions or need assistance, we're here to
                help!
              </span>
              <p>
                Feel free to reach out to us for any inquiries or support
                related to our services. Whether you have a question about
                features, pricing, or anything else, our team is ready to answer
                all your questions. You can contact us via phone, email, or
                through our online form. We aim to respond to all queries within
                24 hours.
              </p>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="input-row">
                <div className="input-box">
                  <input
                    type="text"
                    name="username"
                    placeholder="Name"
                    autoComplete="off"
                    onChange={handleChanges}
                    required
                  />
                </div>
                <div className="input-box">
                  <input
                    type="number"
                    name="mobile"
                    placeholder="Mobile No."
                    autoComplete="off"
                    onChange={handleChanges}
                    required
                  />
                </div>
                <div className="input-box">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    autoComplete="off"
                    onChange={handleChanges}
                    required
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    name="company"
                    placeholder="Company Name"
                    autoComplete="off"
                    onChange={handleChanges}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    name="projectDetails"
                    placeholder="Project Details"
                    autoComplete="off"
                    onChange={handleChanges}
                    required
                  />
                </div>
              </div>

              <div className="submit">
                <input type="submit" className="submit-btn" />
              </div>
            </form>
          </div>
        </div>

        <div className="secondCont">
          <div className="sizeBox">
            <section className="second_sec">
              <div className="title">
                <h2>Located Globally</h2>
              </div>

              <div className="card-row">
                <div className="card">
                  <h4>Office</h4>
                  <div className="mail">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                      style={{
                        width: "20px",
                      }}
                    >
                      <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                      <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                    </svg>{" "}
                    <a href="mailto:hr@webduality.com">hr@webduality.com</a>
                  </div>
                  <div className="call">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                      style={{
                        width: "20px",
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <a href="tel:+91 9358174038">+91 9358174038</a>
                  </div>
                  <div className="call">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                      style={{
                        width: "20px",
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <a href="tel:+91 9592359935">+91 9592359935</a>
                  </div>
                </div>
                <div className="card">
                  <h4>Contact</h4>
                  <div className="mail">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                      style={{
                        width: "20px",
                      }}
                    >
                      <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                      <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                    </svg>{" "}
                    <a href="mailto:contact@webduality.com">
                      contact@webduality.com
                    </a>
                  </div>
                  <div className="call">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                      style={{
                        width: "20px",
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <a href="tel:+91 9358174038">+91 9358174038</a>
                  </div>
                  <div className="call">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-6"
                      style={{
                        width: "20px",
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    <a href="tel:+91 8910624634">+91 8910624634</a>
                  </div>
                </div>
                <div className="card">
                  <h4>Location</h4>
                  <div className="location">
                    <p>
                      Plot No. C, 3, Alpha-II Commercial Belt, Block I, Sector
                      Alpha II, Greater Noida
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3507.0925107665757!2d77.5203415!3d28.4767605!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cebdec5bab77f%3A0x940700cebd9d7ccb!2sWebDuality%20Technologies%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1727112469109!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
