import React from "react";
import "./Marquee.css";

export default function Marquee({
  className,
  reverse,
  pauseOnHover = false,
  children,
  vertical = false,
  repeat = 10,
  ...props
}) {
  return (
    <div
      {...props}
      className={`marquee-container ${!vertical ? "horizontal" : "vertical"} ${
        pauseOnHover ? "pause-on-hover" : ""
      } ${className || ""}`}
    >
      {Array(repeat)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className={`marquee-content ${
              !vertical ? "animate-horizontal" : "animate-vertical"
            } ${reverse ? "reverse" : ""}`}
          >
            {children}
          </div>
        ))}
    </div>
  );
}
