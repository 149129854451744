import React from "react";
import "./ChooseUs.css";

import custom from "./Assets/custom.png";
import perform from "./Assets/performance.png";
import feature from "./Assets/feature.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ChooseUs = () => {
  const dataContent = [
    {
      name: "Customizable",
      para: "Custom software tailored to your needs, with flexible, scalable technology for seamless integration.",
      img: custom,
    },
    {
      name: "Fast Performance",
      para: "Experience rapid performance with our software solutions, delivering speed and efficiency to meet your demands.",
      img: perform,
    },
    {
      name: "Fully Featured",
      para: "Get comprehensive functionality with our fully featured software, designed to cover all your essential needs.",
      img: feature,
    },
  ];

  return (
    <>
      <div className="chooseUsCont">
        <div className="sizeBox">
          <h1>WHY CHOOSE US</h1>
          <p>
            We deliver tailored software solutions with unmatched performance,
            comprehensive features, and seamless integration, all backed by
            dedicated support to ensure your success.
          </p>

          <div className="contentBox">
            {dataContent?.map((e, i) => (
              <ContentItem key={i} element={e} delay={i * 0.2} idx={i} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const ContentItem = ({ element, delay, idx }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // for only once time it run
    threshold: 0.1,
  });

  const variants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      className="cBox"
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={variants}
      transition={{
        duration: 0.8,
        ease: "easeOut",
        delay,
      }}
    >
      <img
        src={element?.img}
        alt=""
        style={{
          background:
            idx === 1
              ? "transparent"
              : "linear-gradient(to top,rgb(75, 127, 240),rgb(104, 79, 241))",
        }}
        draggable="false"
      />
      <h3>{element?.name}</h3>
      <p>{element?.para}</p>
    </motion.div>
  );
};
export default ChooseUs;
