const managerRole = [
  {
    id: 1,
    role: "Project Manager",
    skills: [
      "Project Planning",
      "Agile Methodologies",
      "Team Leadership",
      "Stakeholder Communication",
      "Time Management",
    ],
  },
  {
    id: 2,
    role: "Business Development Executive (BDE)",
    skills: [
      "Lead Generation",
      "Salesforce",
      "Client Relationship Management",
      "Sales Pitching",
      "Negotiation",
    ],
  },
  {
    id: 3,
    role: "Human Resources (HR) Manager",
    skills: [
      "Recruitment",
      "Employee Relations",
      "HR Software",
      "Onboarding",
      "Compensation and Benefits",
    ],
  },
  {
    id: 4,
    role: "Marketing Manager",
    skills: [
      "Digital Marketing",
      "SEO",
      "Social Media Marketing",
      "Campaign Management",
      "Branding",
    ],
  },
];

export default managerRole;
