import React, { useEffect, useState } from "react";
import "./Apply.css";
import axios from "axios";
import { backendURL } from "../../../Helper/BaseUrl";

const Apply = ({ isOpen, setIsOpen, heading, toastAlert, toastSuccess }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    domain: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      domain: heading,
    });
  }, [heading]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCareerSubmit = (event) => {
    event.preventDefault();

    if (formData.name && formData.email && formData.phone) {
      axios
        .post(`${backendURL}/services/create-service`, formData)
        .then((res) => {
          // console.log(res.data);
          toastSuccess("Application Submitted");
        })
        .catch((err) => {
          // console.log(err);
          toastAlert("Server error!!");
        });
    }

    setFormData({
      name: "",
      email: "",
      phone: "",
      domain: "",
    });

    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    // Cleanup function to reset overflow
    return () => {
      document.body.style.overflow = "auto"; // Ensure it's reset on unmount
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      {isOpen && (
        <div className="modal-overlay-apply">
          <div className="modal-content">
            <span>
              Apply for <b>{heading}</b>
            </span>

            <form onSubmit={handleCareerSubmit}>
              <div className="fbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                  style={{
                    width: "20px",
                    color: "#108ea8",
                    marginRight: "5px",
                  }}
                >
                  <path
                    fillRule="evenodd"
                    d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                    clipRule="evenodd"
                  />
                </svg>

                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  onChange={handleFormChange}
                  value={formData?.name}
                  placeholder="Fullname"
                />
              </div>
              <div className="fbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                  style={{
                    width: "20px",
                    color: "#108ea8",
                    marginRight: "5px",
                  }}
                >
                  <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                  <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                </svg>

                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  onChange={handleFormChange}
                  value={formData?.email}
                  placeholder="Email"
                />
              </div>
              <div className="fbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                  style={{
                    width: "20px",
                    color: "#108ea8",
                    marginRight: "5px",
                  }}
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clipRule="evenodd"
                  />
                </svg>

                <input
                  type="text"
                  name="phone"
                  id="phone"
                  required
                  onChange={handleFormChange}
                  value={formData?.phone}
                  placeholder="Phone No."
                />
              </div>

              <div className="btnGrp">
                <button
                  type="submit"
                  style={{
                    backgroundColor: "rgb(5, 109, 5)",
                  }}
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  style={{
                    backgroundColor: "rgb(226, 7, 7)",
                  }}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Apply;
