import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Featureimg.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { FaExternalLinkAlt } from "react-icons/fa";

import apnaParcel from "./Assets/apnaParcel.png";
import doctorsYatra from "./Assets/doctorsYatra.png";
import nearPg from "./Assets/nearPg.png";
import { Link } from "react-router-dom";

const ProjectData = [
  {
    id: 1,
    title: "ApnaParcel",
    description: "ApnaParcel : Safe parcel with apna parcel",
    icon: <FaExternalLinkAlt size={16} color="black" />,
    imgSrc: apnaParcel,
    link: "https://www.apnaparcel.com/",
  },
  {
    id: 2,
    title: "DctorsYatra",
    description: "DctorsYatra : Study to save lives",
    icon: <FaExternalLinkAlt size={16} color="black" />,
    imgSrc: doctorsYatra,
    link: "https://doctors-yatra-frontend.vercel.app/",
  },
  {
    id: 3,
    title: "NearPG",
    description: "NearPg : Find PG near you",
    icon: <FaExternalLinkAlt size={16} color="black" />,
    imgSrc: nearPg,
    link: "#",
  },
  {
    id: 4,
    title: "ApnaParcel",
    description: "ApnaParcel : Safe parcel with apna parcel",
    icon: <FaExternalLinkAlt size={16} color="black" />,
    imgSrc: apnaParcel,
    link: "https://www.apnaparcel.com/",
  },
  {
    id: 5,
    title: "DctorsYatra",
    description: "DctorsYatra : Study to save lives",
    icon: <FaExternalLinkAlt size={16} color="black" />,
    imgSrc: doctorsYatra,
    link: "https://doctors-yatra-frontend.vercel.app/",
  },
  {
    id: 6,
    title: "NearPG",
    description: "NearPg : Find PG near you",
    icon: <FaExternalLinkAlt size={16} color="black" />,
    imgSrc: nearPg,
    link: "#",
  },
];

export default function Projects() {
  return (
    <>
      {" "}
      <div className="main-swiper-project">
        <Swiper
          spaceBetween={30}
          // centeredSlides={true}
          loop={true}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          // pagination={{
          //     clickable: true,
          // }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
          className="mySwiper-main"
        >
          <div className="shadow-left"></div>
          <div className="shadow-right"></div>

          {ProjectData.map((project) => (
            <SwiperSlide key={project.id} style={{ background: "black" }}>
              <div className="project-slide">
                <img
                  src={project.imgSrc}
                  alt={project.title}
                  className="img-project"
                />
                <div className="project-text">
                  <Link
                    className="icon-value"
                    to={project?.link}
                    target="_blank"
                  >
                    {project.icon}
                    <p>Explore</p>
                  </Link>
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
