import React, { useState } from "react";
import "./Career.css";

import Slider from "../../Components/Slider/Slider";

import slide1 from "./Assets/slide1.jpg";
import slide2 from "./Assets/slide2.jpg";
import slide3 from "./Assets/slide3.jpg";

import developerRole from "./Developer/Developer";
import designerRole from "./Designer/Designer";
import managerRole from "./Manager/Manager";
import Apply from "./Apply/Apply";

import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Career = () => {
  const slides = [
    {
      heading: "Avigating the World of Software Careers",
      text: "Navigating a career in software opens up numerous opportunities across various industries. This guide highlights crucial roles, such as software developers, data scientists, and cybersecurity specialists, and the skills necessary for success. Keeping up with trends like cloud computing and artificial intelligence is essential for staying competitive. Additionally, continuous learning and relevant certifications can further enhance career prospects. ",
      image: slide1,
    },
    {
      heading: "The Impact of Software on Modern Careers",
      text: "Software is significantly transforming modern careers by redefining job roles and creating new opportunities across industries. Advanced software technologies, such as cloud computing, artificial intelligence, and data analytics, are reshaping traditional job functions and increasing demand for specialized skills. As technology continues to evolve, professionals must adapt to these changes, influencing career trajectories and setting new industry standards. Staying informed about technological advancements and acquiring relevant skills are crucial for navigating this dynamic landscape.",
      image: slide2,
    },
    {
      heading: "Key Trends Shaping Careers in Software",
      text: "Key trends are shaping careers in software by introducing new opportunities and transforming traditional roles. The rise of cloud computing is enabling remote work and scalable solutions, while artificial intelligence is driving innovation in data analysis and automation. Additionally, the growing emphasis on cybersecurity is creating a demand for professionals skilled in protecting digital assets. Staying current with these trends and acquiring relevant skills, such as proficiency in emerging technologies and continuous learning, is essential for career growth in the software industry.",
      image: slide3,
    },
  ];

  const [tab, setTab] = useState(1);
  const [data, setData] = useState(developerRole);

  const handleTabChange = (index) => {
    setTab(index);
    if (index === 1) {
      setData(developerRole);
    } else if (index === 2) {
      setData(designerRole);
    } else {
      setData(managerRole);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [heading, setHeading] = useState("");

  const toastAlert = (msg) => {
    toast.error(`${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const toastSuccess = (msg) => {
    toast.success(`${msg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <>
      <div className="careerContainer">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />

        <div>
          <Slider slides={slides} />
        </div>

        <div className="sizeBox">
          <div className="textBox">
            <h1>Please join us</h1>
            <p>
              Review the current job openings below. If none of these positions
              are a perfect fit or if you believe your unique skills could
              benefit our team in other ways, complete the form below or contact
              us directly at hr@webduality.com. We are keen to consider how you
              might contribute to our organization.
            </p>
          </div>

          <div className="tabContSlide">
            <button
              onClick={() => {
                handleTabChange(1);
              }}
            >
              Developer
            </button>
            <button
              onClick={() => {
                handleTabChange(2);
              }}
            >
              Designer
            </button>
            <button
              onClick={() => {
                handleTabChange(3);
              }}
            >
              Manager
            </button>
          </div>

          <div className="tabBox">
            {data?.map((d) => (
              <div className="tabBoxChild" key={d?.id}>
                <div className="tChild">
                  <span>Role</span>
                  <h3>{d?.role}</h3>
                  <button
                    className="applyNow"
                    onClick={() => {
                      setIsOpen(true);
                      setHeading(d?.role);
                    }}
                  >
                    Apply Now
                  </button>
                </div>
                <hr />
                <div className="tChild">
                  <span>Skills</span>
                  <div className="skillBox">
                    {d?.skills?.map((s, skillIdx) => (
                      <span key={`${d?.id}-skill-${skillIdx}`}>{s}</span>
                    ))}
                  </div>
                </div>
                <hr />
                <div className="tChild">
                  <span>Location</span>
                  <p>
                    <b>Remote</b>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Apply
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          heading={heading}
          toastAlert={toastAlert}
          toastSuccess={toastSuccess}
        />
      </div>
    </>
  );
};

export default Career;
