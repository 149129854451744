import React, { useEffect, useRef, useState } from "react";
import "./Home.css";

/* ------- Components ------- */
import HomeSlider from "../../Components/HomeSlider/HomeSlider";
import HomeSliderHero from "../../Components/HomeSliderHero/HomeSliderHero";

/* ------- Images and Videos ------- */
import video1 from "./Assets/video1.mp4";
import video2 from "./Assets/video2.mp4";
import image1 from "./Assets/image1.png";
import image2 from "./Assets/image2.jpg";
import TeamSlider from "../../Components/TeamSlider/TeamSlider";

import ServicesTimeline from "../../Components/HomeComponents/TimeLine/ServicesTimeline";
import HomeMarquee from "../../Components/Animation/HomeMarquee";
import Projects from "../../Components/HomeComponents/FeatureImg/FeatureImg";
import ChooseUs from "../../Components/HomeComponents/ChooseUs/ChooseUs";
import { Link } from "react-router-dom";

const Home = () => {
  const [heroCount, setHeroCount] = useState(0);
  const [playStatus, setPlayStatus] = useState(true);

  // Images and Videos Details
  const images = [
    {
      src: video1,
    },
    {
      src: image1,
    },
    {
      src: video2,
    },
    {
      src: image2,
    },
  ];

  // Content Details
  const content = [
    {
      heading: "Enjoy Unlimited Growth Without Worries",
      para: "Empower Your Business with Cutting-Edge Tech Solutions: Transformative Innovations Await!",
    },
    {
      heading: "Building dev team, Quickly and Affordably",
      para: "Empower Your Business with Cutting-Edge Tech Solutions: Transformative Innovations Await!",
    },
    {
      heading: "Growing a Development Team, Swiftly and Economically",
      para: "We specialize in helping you build a team of expert developers, testers, and leaders. We are setup to deliver long term solutions, or scale to different needs quickly.",
    },
    {
      heading: "Constructing a Tech Team, Rapidly and Cost-Effectively",
      para: "We specialize in helping you build a team of expert developers, testers, and leaders. We are setup to deliver long term solutions, or scale to different needs quickly.",
    },
  ];

  // Auto Changing Slider Every 8second
  useEffect(() => {
    if (playStatus) {
      const interval = setInterval(() => {
        setHeroCount((prevHeroCount) =>
          prevHeroCount === 3 ? 0 : prevHeroCount + 1
        );
      }, 8000);

      // Cleanup function to clear the interval
      return () => clearInterval(interval);
    }
  }, [playStatus]);

  const secondDivRef = useRef(null);

  const scrollToNextDiv = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="home-container">
      {/* Home Slider*/}
      <div className="home-top">
        {/* Home Slider for Video and Images */}
        <HomeSlider
          playStatus={playStatus}
          heroCount={heroCount}
          images={images}
        />
        {/* Home Slider Hero for handling the slider and data */}
        <HomeSliderHero
          playStatus={playStatus}
          setPlayStatus={setPlayStatus}
          heroCount={heroCount}
          setHeroCount={setHeroCount}
          content={content[heroCount]}
          data={images.length}
          secondDivRef={secondDivRef}
          scrollToNextDiv={scrollToNextDiv}
        />
      </div>

      {/* Choose Us Section */}
      <div className="info-section" ref={secondDivRef}>
        <ChooseUs />
      </div>

      {/* Services Timeline Section */}
      <div>
        <ServicesTimeline />
      </div>

      {/* TeamSlider Section */}
      <div>
        <TeamSlider />
      </div>

      {/* Marquee Section */}
      <div style={{ position: "relative" }}>
        <div className="blur-effect-left"></div>
        <HomeMarquee />
        <div className="blur-effect-right"></div>
      </div>

      {/* Our Projects Section */}
      <div className="our-project-home">
        <h1 className="our-head">Featured Projects</h1>
        <p className="our-para">
          Discover some of our key projects that showcase our expertise in
          delivering innovative and impactful solutions.
        </p>
        <Projects />
      </div>

      {/* Join Us Section */}
      <div className="joinUs">
        <div style={{ textAlign: "center", padding: "15px" }}>
          <h1 className="companyName">WebDuality Technologies Pvt. Ltd.</h1>
        </div>
        <div className="service-last">
          <h1>Let’s Create an amazing project together</h1>
          <div className="btn-one">
            <Link to="/contact" style={{ color: "#000" }}>
              Join us
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#fff",
                borderRadius: "50%",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              <path
                fillRule="evenodd"
                d="M13.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M19.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
