import React, { useState, useEffect } from "react";
import "./Technology.css";
import { TechData, TechData2 } from "./TechData";
import { Link } from "react-router-dom";
import Slider from "../../Components/Slider/Slider";
import slide1 from "./assets/slide1.jpg";
import slide2 from "./assets/slide2.jpg";
import slide3 from "./assets/slide3.jpg";

const slides = [
  {
    heading: "Top Trends in AI for 2024",
    text: "As we move into 2024, artificial intelligence (AI) is set to transform industries in unprecedented ways. Key trends include the rise of generative AI, which is revolutionizing content creation and automation. Ethical considerations and regulatory frameworks are gaining importance, ensuring responsible AI development and deployment. AI's impact on healthcare continues to grow, with advancements in diagnostics and personalized treatments. ",
    image: slide1,
  },
  {
    heading: "How Software is Revolutionizing Industries",
    text: "Software is revolutionizing various industries by introducing innovative solutions that enhance efficiency and streamline operations. Cutting-edge applications are improving accuracy in diagnostics, enabling early detection of issues, and facilitating personalized approaches in diverse fields. Advanced data management systems are optimizing administrative tasks, efficiently handling records, and predicting outcomes with greater precision. ",
    image: slide2,
  },
  {
    heading: "The Essential Guide to Software Innovations",
    text: "Software innovations are at the forefront of transforming modern industries, driving significant advancements in efficiency and functionality. This guide explores key innovations such as cloud computing, which offers scalable resources and remote accessibility, and artificial intelligence, which enhances data analysis and decision-making. Additionally, advancements in cybersecurity software are improving data protection and privacy, while development tools like low-code platforms are accelerating application creation.",
    image: slide3,
  },
];

const Technology = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeCard, setActiveCard] = useState(0); // To track the current active card
  const [isPaused, setIsPaused] = useState(false); // To pause the animation on hover

  const totalCards = 6; // Total number of cards

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setActiveCard((prev) => (prev + 1) % totalCards); // Cycle through cards
      }, 3000); // 3 seconds per card
      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [isPaused]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div className="Technology-main">
      <div>
        <Slider slides={slides} />
      </div>
      <div className="tech-middle">
        <h2>
          Empowering Technology with Cutting-Edge Solutions and Dependable
          Support
        </h2>
        <p className="para-tech" style={{ fontSize: "1.15rem" }}>
          WebDuality is an established IT company that aims to deliver robust
          solutions by harnessing the latest web technologies. Our innovative
          and skilled team is efficient in fulfilling the unique requirements of
          clients by complying with the recent industry standards.{" "}
        </p>
        <div className="tech-icons-all">
          {TechData.map((tech, index) => (
            <div
              key={index}
              className={`tech-icon tech-anime ${activeCard === index ? 'activeone' : ''}`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="tech-cut1"></div>
              <div className="tech-corner1 tc3"></div>
              <div className="tech-cut2"></div>
              <div className="tech-corner2 tc4"></div>
              <div className="lines"></div>

              <div className="icon-img-tech icon-img-tech2">
                <img src={tech.icon} alt={tech.name} />
              </div>
              <p style={{ margin: "20px 0" }} className="above-head">
                {tech.name}
              </p>
              <div className="content">
                <div className="details">
                  <p className="techlast-para">{tech.para}</p>
                  <Link to={tech?.link} className="btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="tech-icons-all2">
          <div className="main-tech2">
            {TechData2.map((tech, index) => (
              <div
                key={index}
                className={`tech-icon tech-icon1 ${activeIndex === index ? "active" : ""
                  } ${activeIndex !== null && activeIndex !== index ? "blur" : ""}`}
                style={{ backgroundColor: "#ffffff" }}
              >
                <div
                  className="tech-cut1"
                  style={{ backgroundColor: "#000000" }}
                ></div>
                <div
                  className="tech-corner1 tc1"
                  style={{ backgroundColor: "#000000" }}
                ></div>
                <div
                  className="tech-cut2"
                  style={{ backgroundColor: "#000000" }}
                ></div>
                <div
                  className="tech-corner2 tc2"
                  style={{ backgroundColor: "#000000" }}
                ></div>
                <div className="lines"></div>
                <div className="curve-tech"></div>
                <div
                  className="icon-img-tech icon-img-tech1"
                  style={{ marginTop: "5px" }}
                >
                  <img
                    src={tech.icon}
                    alt={tech.name}
                    style={{ width: "85px" }}
                  />
                  <h3 className="tech-para2" style={{ color: "#000" }}>
                    {tech.name}{" "}
                  </h3>
                </div>
                <div className="content">
                  <div className="details">
                    <p className="techlast-para">{tech.para}</p>
                    <Link to={tech?.link} className="btn">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technology;
