import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./Timeline.css";

const Timeline = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        height: "100%",
        transition: { duration: 6, ease: "linear" },
      });
    }
  }, [controls, inView]);

  return (
    <div className="main-timeline1">
      <motion.div
        ref={ref}
        className="timeline1"
        initial={{ height: "0" }}
        animate={controls}
      ></motion.div>
    </div>
  );
};

export default Timeline;
