import React, { useState, useEffect } from "react";
import "./Start.css";
import { firstStartBox, secondStartBox } from "./StartBox";
import bg_video from "./videoplayback (3).webm";
import Slider from "../../Components/Slider/Slider";
import img1 from "./Assets/sliderImages/img1.jpg";
import img2 from "./Assets/sliderImages/img2.jpg";
import img3 from "./Assets/sliderImages/img3.jpg";
import img4 from "./Assets/sliderImages/img4.jpg";
import img5 from "./Assets/sliderImages/img5.jpg";
import img6 from "./Assets/sliderImages/img6.jpg";
import img7 from "./Assets/sliderImages/img7.jpg";

const slides = [
  {
    heading: "Getting Started",
    text: "Kick off your journey by clearly defining your business goals. Explore our wide range of innovative solutions tailored to meet your unique needs. Consult with our experienced experts to customize a comprehensive plan that fits your business objectives. Implement the solutions seamlessly into your operations and benefit from our ongoing support every step of the way, ensuring long-term success and continuous improvement.",
    image: img1,
},
{
    heading: "Enjoy Unlimited Growth Without Worries",
    text: "Unlock the full potential of your business with our cutting-edge technology solutions. Experience transformative innovations designed to drive growth, streamline operations, and enhance productivity. Our solutions are built to scale with your business, ensuring you can focus on growth without being held back by technology limitations. Let us take care of the tech while you focus on expanding your business.",
    image: img2,
},
{
    heading: "Building a Dev Team, Quickly and Affordably",
    text: "We provide you with the tools and expertise to rapidly build a development team that fits your business's needs. Whether you're looking for short-term expertise or a long-term team, our flexible solutions allow you to scale your team quickly and cost-effectively. Our focus is on providing high-quality developers, testers, and project managers to ensure your projects are completed on time and within budget.",
    image: img3,
},
{
    heading: "Growing a Development Team, Swiftly and Economically",
    text: "We specialize in helping businesses expand their development teams with skilled professionals, including expert developers, testers, and team leaders. Our setup ensures we can deliver long-term, scalable solutions tailored to your specific requirements. Whether you need to augment your current team or scale up to meet project demands quickly, we offer a cost-effective approach to growing your team without compromising on quality.",
    image: img4,
},

];

const Start = () => {
  const [animationsShown, setAnimationsShown] = useState({});
  const [developerCount, setDeveloperCount] = useState(0);

  const startCountAnimation = (maxCount) => {
    let count = 0;
    const interval = setInterval(() => {
      count += 1; // Increment by 10 (or adjust for smoother/faster animation)
      setDeveloperCount(count);
      if (count >= maxCount) {
        clearInterval(interval); // Stop the animation once it reaches maxCount
      }
    }, 30); // Adjust the interval for speed control (lower means faster)
  };

  // Handle Intersection Func
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !entry.target.classList.contains("show")) {
        entry.target.classList.add("show");

        if (entry.target.classList.contains("developers-image")) {
          startCountAnimation(100);
        }

        // Update the state to mark this animation as shown
        setAnimationsShown((prev) => ({
          ...prev,
          [entry.target.dataset.animationKey]: true,
        }));
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    const targetRefs = document.querySelectorAll(".hidden");
    const targetRefs1 = document.getElementsByClassName("developers-image")[0];

    targetRefs.forEach((targetRef) => {
      const animationKey = targetRef.dataset.animationKey;
      const animationKey1 = targetRefs1.dataset.animationKey;

      if (!animationsShown[animationKey1]) {
        observer.observe(targetRefs1);
      }

      // Check if the animation for this box has already been shown
      if (!animationsShown[animationKey]) {
        observer.observe(targetRef);
      } else {
        // If the animation has already been shown, add the 'show' class immediately
        targetRef.classList.add("show");
      }
    });

    return () => {
      targetRefs.forEach((targetRef) => {
        observer.unobserve(targetRef);
      });
    };
  }, [animationsShown]); // Add animationsShown to the dependency array

  const textPositionStyle = {
    position: "relative",
    textAlign: "start",
    alignSelf: "start",
    width: '50%',
    top: "30%",
    left: "30%",
    transform: "translate(-50%, -50%)",
    color: "white",
    height: "100%",
    paddingBottom: "10px",
    fontSize: "34px",
    fontWeight: "600",
  };
  const textPositionStyle2 = {
    position: "relative",
    width: '50%',
    top: "40%",
    left: "14%",
    marginTop: "0px",
    textAlign: "start",
    transform: "translate(-50%, -50%)",
    color: "white",
    height: "100%",
  };

  return (
    <>
      <div>
        <Slider slides={slides} textPosition={textPositionStyle} textpara={textPositionStyle2} />
      </div>

      <div className="startPage">
        <div className="secondStartCont">
          <div className="sizeBox">
            <h1>How to Get Started with Courtney</h1>
            <p>
              From full-time remote engineering teams to hourly contractors,
              work with remote devs as needed
            </p>

            <div className="box">
              {firstStartBox.map((e, i) => {
                return (
                  <div
                    className="inBox hidden"
                    data-animation-key={`secondStartCont${i}`}
                    key={i}
                  >
                    <img src={e.img} alt="" draggable="false" />
                    <span>
                      <p>{e.heading}</p>
                      <p>{e.para}</p>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="thirdStartCont">
          <div className="sizeBox">
            <h2>Why You Need To Hire Remote Developers</h2>

            <div className="box">
              {secondStartBox.map((e, i) => {
                return (
                  <h4
                    className="hidden"
                    data-animation-key={`thirdStartCont${i}`}
                    key={i}
                  >
                    {e.heading}
                  </h4>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className="developers-image"
          style={{
            width: "90%",
            height: "450px",
            position: "relative",
            overflow: "hidden",
          }}
          data-animation-key={`counter`}
        >
          <video
            autoPlay
            muted
            loop
            playsInline
            className="video-background"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              filter: "brightness(60%)",
            }}
          >
            <source src={bg_video} type="video/mp4" />
          </video>

          <div className="image-content">
            <h1>{developerCount}+ Developers</h1>
            <p>Tell us more about your requirements and technical needs</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Start;
