const developerRole = [
  {
    id: 1,
    role: "Full Stack Developer",
    skills: ["NodeJs", "ReactJS", "ExpressJS", "Typescript", "MongoDB", "SQL"],
  },
  {
    id: 2,
    role: "Frontend Developer",
    skills: ["HTML", "CSS", "JavaScript", "ReactJS", "VueJS", "Tailwind CSS"],
  },
  {
    id: 3,
    role: "Backend Developer",
    skills: ["NodeJs", "ExpressJS", "Python", "Django", "PostgreSQL", "MongoDB"],
  },
  {
    id: 4,
    role: "Mobile App Developer",
    skills: ["Flutter", "React Native", "Swift", "Kotlin", "Dart", "Firebase"],
  },
];

export default developerRole;
