import React, { useEffect, useRef, useState } from "react";
import "./HomeSlider.css";

const HomeSlider = ({ playStatus, heroCount, images }) => {
  const [isVideoPlayable, setIsVideoPlayable] = useState(true);
  const videoRef = useRef(null);

  // Handle video playback and source changes
  useEffect(() => {
    const handleVideoPlayback = () => {
      if (videoRef.current) {
        if (playStatus) {
          videoRef.current.play().catch(() => {
            // Handle playback error or auto-play policy restriction
            setIsVideoPlayable(false);
          });
        } else {
          videoRef.current.pause();
        }
      }
    };

    handleVideoPlayback();
  }, [playStatus, heroCount]);

  // Reset video ref when heroCount changes
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      videoRef.current.load(); // Reload the video to ensure the source updates
    }
  }, [heroCount]);

  const renderContent = () => {
    const currentImage = images[heroCount]?.src;

    if (heroCount === 1 || heroCount === 3) {
      return (
        <img
          src={currentImage}
          alt="background"
          className="homeBackground fade-in"
        />
      );
    }

    return (
      <video
        ref={videoRef}
        autoPlay={playStatus}
        className="homeBackground fade-in"
        loop
        muted
      >
        <source src={currentImage} type="video/mp4" />
      </video>
    );
  };

  return <>{renderContent()}</>;
};

export default HomeSlider;
