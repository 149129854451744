import React from "react";
import "./HomeMarquee.css";

import Reactlogo from "./assets/Reactlogo.png";
import Angularlogo from "./assets/Angularlogo.png";
import Nodelogo from "./assets/Nodelogo.png";
import Mongodblogo from "./assets/MongoDB.png";
import expressLogo from "./assets/express.jpeg";
import sqlLogo from "./assets/sql.jpg";
import tsLogo from "./assets/typescript.jpg";
import phpLogo from "./assets/php.jpg";

import javaLogo from "./assets/java.png";
import cSharpLogo from "./assets/cSharp.png";
import pythonLogo from "./assets/python.jpg";
import dotnetLogo from "./assets/dotnet.png";
import djangoLogo from "./assets/django.png";
import gCloudLogo from "./assets/gCloud.png";
import awsLogo from "./assets/aws.png";
import azureLogo from "./assets/azure.png";

import Marquee from "./Marquee";

const MarqueeData1 = [
  {
    name: "React",
    logo: Reactlogo,
  },
  {
    name: "Angular",
    logo: Angularlogo,
  },
  {
    name: "Node.js",
    logo: Nodelogo,
  },
  {
    name: "Mongodb",
    logo: Mongodblogo,
  },
  {
    name: "Express",
    logo: expressLogo,
  },
  {
    name: "SQL",
    logo: sqlLogo,
  },
  {
    name: "Typescript",
    logo: tsLogo,
  },
  {
    name: "PHP",
    logo: phpLogo,
  },
];

const MarqueeData2 = [
  {
    name: "Java",
    logo: javaLogo,
  },
  {
    name: "C#",
    logo: cSharpLogo,
  },
  {
    name: "Python",
    logo: pythonLogo,
  },
  {
    name: "DotNet",
    logo: dotnetLogo,
  },
  {
    name: "Django",
    logo: djangoLogo,
  },
  {
    name: "Google Cloud",
    logo: gCloudLogo,
  },
  {
    name: "AWS",
    logo: awsLogo,
  },
  {
    name: "Azure",
    logo: azureLogo,
  },
];

export default function HomeMarquee() {
  return (
    <div className="client-section above-head">
      <div className="text-center">
        <div className="marquee-wrapper">
          <Marquee pauseOnHover className="custom-marquee">
            {MarqueeData1.map((marqud, index) => (
              <div key={index} className="marquee-item">
                <img
                  src={marqud.logo}
                  alt={marqud.name}
                  className="client-logo"
                  draggable="false"
                />
              </div>
            ))}
          </Marquee>
        </div>
        <div className="marquee-wrapper">
          <Marquee pauseOnHover className="custom-marquee" reverse>
            {MarqueeData2.map((marqud, index) => (
              <div key={index} className="marquee-item">
                <img
                  src={marqud.logo}
                  alt={marqud.name}
                  className="client-logo"
                  draggable="false"
                />
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
}
