import React from "react";
import "./HomeSliderHero.css";
import { Link } from "react-router-dom";

const HomeSliderHero = ({
  data,
  playStatus,
  setPlayStatus,
  heroCount,
  setHeroCount,
  content,
  secondDivRef,
  scrollToNextDiv,
}) => {
  const handleHeroCount = (i) => {
    setHeroCount(i);
    setPlayStatus(true);
  };

  return (
    <div className="heroHomeSlider">
      <div className="sizeBox">
        <div className="tag">
          <h1>{content.heading}</h1>
          <p>{content.para}</p>
          <div className="btn">
            <button>
              <Link to="/contact">Contact us</Link>
            </button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#fff",
                borderRadius: "50%",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              <path
                fillRule="evenodd"
                d="M13.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M19.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div className="hero-dot-play">
          <ul className="hero-dots">
            {Array.from({ length: data }).map((_, i) => (
              <li
                key={i}
                onClick={() => handleHeroCount(i)}
                className={heroCount === i ? "hero-dot orange" : "hero-dot"}
              ></li>
            ))}
          </ul>

          <div className="hero-play">
            {playStatus ? (
              <div onClick={() => setPlayStatus(false)} className="loader">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                  />
                </svg>
              </div>
            ) : (
              <div onClick={() => setPlayStatus(true)} className="loader">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>

        <div className="scroll" onClick={() => scrollToNextDiv(secondDivRef)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default HomeSliderHero;
