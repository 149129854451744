import img1 from '../assets/img1.png'
import img2 from '../assets/img2.png'
import img3 from '../assets/img3.png'
import img4 from '../assets/img4.png'

export const ServiceData = [
    {
        title: 'Dedicated Teams',
        description: 'Building an extended team with Coursetry is just like opening your own remote development center, but without the hassle.',
        iconimg: img1
    },
    {
        title: 'Staff Augmentation',
        description: 'IT Staff Augmentation is a service designed to add extra talent to your team on an on-demand basis. This allows businesses to immediately find the right fit.',
        iconimg: img2
    },
    {
        title: 'Software Outsourcing',
        description: 'Today, organizations all over the world deal with software outsourcing companies to have access to experienced software engineers.',
        iconimg: img3
    },
    {
        title: 'Remote Office',
        description: 'Coursetry is a trustworthy partner that can help you open your own remote development center and grow your business from its London and United Kingdom.',
        iconimg: img4
    },
];


export const ServiceSliderData = [
    {
        heading: 'Slide 1',
        text: 'This is the first slide in the list',
        image: 'https://plus.unsplash.com/premium_photo-1684581214880-2043e5bc8b8b?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
        heading: 'Slide 2',
        text: 'This is the second slide',
        image: 'https://images.unsplash.com/photo-1472289065668-ce650ac443d2?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
        heading: 'Slide 3',
        text: 'This is the third slide',
        image: 'https://plus.unsplash.com/premium_photo-1661600114108-3179188e1ab3?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
        heading: 'Slide 4',
        text: 'This is the fourth slide',
        image: 'https://images.unsplash.com/photo-1432821596592-e2c18b78144f?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
];