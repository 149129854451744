import React, { useState, useEffect, useRef } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./Slider.css";

const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const [value, setValue] = useState(0);

  const changeSlide = (direction) => {
    setCurrentIndex((prevIndex) => {
      if (direction === "next") {
        return prevIndex === slides.length - 1 ? 0 : prevIndex + 1;
      } else {
        return prevIndex === 0 ? slides.length - 1 : prevIndex - 1;
      }
    });
    setValue((prevIndex) => {
      if (direction === "next") {
        return prevIndex === slides.length - 1 ? 0 : prevIndex + 1;
      } else {
        return prevIndex === 0 ? slides.length - 1 : prevIndex - 1;
      }
    });
  };

  // Handle automatic slide change
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
      setValue((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="slider-main" ref={sliderRef}>
      <div className="background-color"></div>
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === currentIndex ? "active" : ""}`}
        >
          <img src={slide.image} alt={slide.heading} />
        </div>
      ))}
      {slides.map((i) => {
        return (
          <div className="slide-content" key={i}>
            <h2>{slides[value]?.heading}</h2>
            <p>{slides[value]?.text}</p>
          </div>
        );
      })}
      <button className="arrow left-arrow " onClick={() => changeSlide("prev")}>
        <FaArrowLeft />
      </button>
      <button className="arrow right-arrow" onClick={() => changeSlide("next")}>
        <FaArrowRight />
      </button>
    </div>
  );
};

export default Slider;
